sl-dialog section {
  padding: 0 !important;
}

sl-button {
  & sl-icon {
    font-size: 1.2em;
  }

  & a {
    color: inherit;
    text-decoration: none;
  }
}

sl-card {
  transform: translateY(0px) rotate(0deg);
  transition: transform 0.15s cubic-bezier(0.45, 0, 0.55, 1);

  &::part(base) {
    transition: box-shadow 0.15s cubic-bezier(0.45, 0, 0.55, 1);
    box-shadow: var(--sl-shadow-large);
    border-top: 5px solid var(--sl-color-primary-500);
  }

  & h3, & p:last-of-type {
    margin-bottom: 0;
  }

  & [slot="footer"] {
    display: flex; 
    justify-content: space-between; 
    align-items: center;
  }

  &:hover::part(base) {
    box-shadow: var(--sl-shadow-x-large);
  }
}


.masonry-item:nth-child(odd) {
  & sl-card {
    transform-origin: 70% 70%;
  }

  & sl-card:hover {
    transform: translateY(-6px) rotate(-2.5deg);
  }
}

.masonry-item:nth-child(even) {
  & sl-card {
    transform-origin: 30% 70%;
  }

  & sl-card:hover {
    transform: translateY(-6px) rotate(2.5deg);
  }
}

sl-dialog {
  &::part(panel) {
    @media (max-width: 420px) {
      max-height: 95vh;
    }
  }

  & h3[slot=label] {
    display: inline-block;
    margin-bottom: 0;
    font-size: 1.3em;
    text-align: left;
  }

  &::part(footer) {
    z-index: 100;
    box-shadow: 0px -10px 20px rgba(0,0,0,0.1);
  }
}

sl-spinner {
  font-size: 3rem;
  --indicator-color: var(--color-orange);
  --track-color: #ffc399;
}

p > a > sl-icon {
  vertical-align: -0.18em;
  margin-left: 0.2em;
  margin-right: 0.2em;
}
