:root {
  --body-background: var(--sl-color-neutral-50);
  --body-color: var(--sl-color-neutral-700);
  --body-size: 19px;

  --sl-font-sans: RedHatText, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --display-sans: RedHatDisplay, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --display-serif: DMSerif, Georgia, 'Times New Roman', serif;

  --body-font-weight: 425;
  --body-line-height: 1.5;

  --color-dark-green: #14574E;
  --color-medium-green: #246e62;
  --color-light-green: #218675;
  --color-orange: #f47c3c;
  --color-light-orange: #ff9359;
  --color-brick: #C14849;

  --sl-border-radius-medium: 0.35rem;

  --sl-color-primary-600: var(--color-medium-green);
  --sl-color-primary-500: var(--color-light-green);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  18% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes swingBackAndForth {
  0% {
    transform: rotate(-7deg);
  }
  4.5% {
    transform: rotate(-16deg);
  }
  11.5% {
    transform: rotate(-7deg);
  }
  18% {
    transform: rotate(-10deg);
  }
  25% {
    transform: rotate(-7deg);
  }
  30% {
    transform: rotate(-8deg);
  }
  37% {
    transform: rotate(-7deg);
  }
}

*[hidden] {
  display: none;
}

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

html {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-size: var(--body-size);
}

body {
  margin: 0;
  padding: 0;
  background: var(--body-background);
  color: var(--body-color);
  font-family: var(--sl-font-sans);
  font-weight: var(--body-font-weight);
  line-height: var(--body-line-height);
}

h1, h2, h3, h4, h5 {
  font-family: var(--display-sans);
  font-weight: 900;
  letter-spacing: -0.01em;
  margin-top: 0;
  color: var(--sl-color-neutral-600);

  &.serif {
    font-family: var(--display-serif);
    font-weight: 400;
  }

  &.colorful {
    color: var(--color-dark-green);
  }
}

h1 {
  margin-top: 0;
  font-size: 4em;
  text-transform: uppercase;
  text-shadow: 0px 2px 4px rgba(20, 0, 80, 0.7);
  color: white;
  opacity: 0.9;
}

h2 {
  font-size: 3em;
  line-height: 1.15;

  @media (max-width: 499px) {
    font-size: 2.5em;
  }

  &.serif {
    font-size: 3.2em;

    @media (max-width: 499px) {
      font-size: 2.7em;
    }
  }
}

h3 {
  font-size: 1.8em;
  line-height: 1.4;
}

h4 {
  font-size: 1.4em;
  margin: 1.75em 0 1em;
}

h1, h2, h3, nav, footer {
  text-align: center;
}

p, ul, ol, blockquote {
  margin-top: 0;
  margin-bottom: 1.3em;
  word-break: break-word;
}

a {
  color: var(--color-brick);
  text-underline-offset: 0.15em;
  text-decoration-color: var(--color-light-orange);
}

hr {
  border: none;
  border-top: 3px solid var(--sl-color-neutral-300);
  margin: 2rem auto;
}

main {
  margin: 0 0 4rem;
}

section-wrapper {
  display: block;
}

section-wrapper[invert], footer {
  background: var(--sl-color-neutral-900);
  color: var(--sl-color-neutral-50);

  & h2 {
    color: var(--color-brick);
  }

  & a {
    color: var(--color-orange);
    text-decoration-color: var(--color-brick);
  }
}

section {
  padding: 2rem 0.75rem;
  margin: 0 auto;
  max-width: 65rem;

  &.masonry {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 0;
    gap: 1.5rem;
    max-width: 85rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    @media (max-width: 1100px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 750px) {
      grid-template-columns: 1fr;

      padding-left: 0.75rem;
      padding-right: 0.75rem;
    }
  }

  @media (min-width: 500px) {
    padding: 2rem 3rem;
  }

  & ul, & ol {
    margin-left: var(--sl-spacing-x-large);
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;

    & > li {
      margin: 0 0 var(--sl-spacing-small);

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  & ul {
    list-style-type: square;
  }
}

header, header-background {
  height: 42vw;
}

header-background {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
  overflow: hidden;

  background: linear-gradient(#5F75A5, #796F7F);

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center 35%;
  }
}

header-content {
  display: block;
  z-index: 1;
  position: relative;
  padding-top: 40px;
  color: white;

  & h2 {
    position: absolute;
    left: 50%;
    margin: 0;
    font-size: 1rem;
    letter-spacing: 0;
    transform: rotate(-7deg);
    transform-origin: 99% 2%;
    background: var(--color-orange);
    color: white;
    margin-left: 6.3rem;
    padding: 0.25em 0.7em;
    border-radius: 4px 2px 4px 4px;
    top: 7.3rem;
    box-shadow: 0px 11px 6px -7px rgba(0,0,0,0.15);
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);

    animation: swingBackAndForth 8s;
    animation-delay: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
}

@media (max-width: 767px) {
  header, header-background {
    height: 60vw;
  }


  h1 {
    font-size: 2.5em;
  }

  header-content {
    padding-top: 30px;

    & h2 {
      margin-left: 3rem;
      top: 5rem;
      font-size: 0.9rem;
    }
  }
}

footer {
  padding: 3rem 20px;
}

.fade-in-animation {
  opacity: 0;
  animation: fadeIn 2s 0.5s;
  animation-fill-mode: forwards;
}

p.heading-icon {
  text-align: center;
  font-size: 250%;
  margin-bottom: 0;
  color: var(--sl-color-neutral-400);
}

section-wrapper[invert] p.heading-icon {
  color: var(--color-medium-green);
}
