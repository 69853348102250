@import "../fonts/dm/dm.css";
@import "../fonts/redhat/redhat.css";

@import "global.css";
@import "shoelaced.css";
@import "syntax.css";

/* sl-button[type="success"][size="large"]::part(base) {
  box-shadow: rgba(25, 150, 50, 0.35) 0px 20px 15px -15px;
  transition: all ease 0.25s;

  &:hover {
    transform: translate(0, -6px);
    box-shadow: rgba(25, 150, 50, 0.35) 0px 23px 15px -15px;
  }
} */
